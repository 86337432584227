/**
 * Import utils modules
 */
import convertToArray from "./modules/convert-to-array";
import thereIsATargetElem from "./modules/there-is-a-target-elem";
import setClassIsActive from "./modules/set-class-is-active";

const clickCategoryItem = (clickedItem, listsLogo) => {
    let elemIsActived = listsLogo.filter(item => {
        if(thereIsATargetElem(item, clickedItem)) {
            return setClassIsActive(item);
        } else {
            item.classList.remove("is-active");
        }
    })[0];

    return elemIsActived;
};

const runActions = (elementsToSetClickActions) => {
    const LISTS_PRODUCT_LOGO = convertToArray(
        document.querySelectorAll(".block-asidemenu-content__target")
    ).map(item => item);

    const ELEM_MSG = document.querySelector(".block-asidemenu-content__msg");

    elementsToSetClickActions.map(item => item.addEventListener("click", e => {
        if(clickCategoryItem(e.target, LISTS_PRODUCT_LOGO) === undefined) {
            ELEM_MSG.classList.add("is-active");
            ELEM_MSG.innerHTML = "Produtos não cadastrados!";
        } else {
            ELEM_MSG.classList.remove("is-active");
            ELEM_MSG.innerHTML = "";
        }
    }));
};

export default () => {
    const BLOCK_ASIDEMENU_CONTENT_MENU_ITEM = convertToArray(
        document.querySelectorAll(".block-asidemenu-content__menu li")
    );

    if(BLOCK_ASIDEMENU_CONTENT_MENU_ITEM) runActions(BLOCK_ASIDEMENU_CONTENT_MENU_ITEM);
};
