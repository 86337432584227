module.exports = () => {
    const BLOCK_READ_MORE_FADE = document.querySelector(".black-read-more-fade");
    if(!BLOCK_READ_MORE_FADE) return;

    const ELEMENT_SET_IS_READ_MORE = document.querySelector(".js-set-is-read-more");
    const BLACK_READ_MORE_FADE_BUTTON = BLOCK_READ_MORE_FADE.querySelector("button");

    BLACK_READ_MORE_FADE_BUTTON.addEventListener("click", () => {
        if(!BLOCK_READ_MORE_FADE.classList.contains("is-read-more")) {
            BLACK_READ_MORE_FADE_BUTTON.textContent = "Esconder texto";
        } else {
            BLACK_READ_MORE_FADE_BUTTON.textContent = "Leia mais";
        }

        ELEMENT_SET_IS_READ_MORE.classList.toggle("is-read-more");
        BLOCK_READ_MORE_FADE.classList.toggle("is-read-more");
    });
};
