import { Swiper, Navigation, Pagination } from "swiper/dist/js/swiper.esm.js";

Swiper.use([Navigation, Pagination]);

export default () => {
    const setPagination = {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    };

    const productsSlide = new Swiper('.products-slide--slide', {
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            // when window width is >= 320px
            400: {
                slidesPerView: 1,
                pagination: setPagination
            },
            900: {
                slidesPerView: 3,
                pagination: setPagination
            }
        }
    });

    const swiperAvailableFormats = new Swiper('.swiper-available-formats', {
        slidesPerView: 13,
        pagination: {},
        breakpoints: {
            400: {
                slidesPerView: 1,
                pagination: setPagination
            },
            610: {
                slidesPerView: 8,
                pagination: setPagination
            }
        }
    });
};
