module.exports = () => {
    if(!document.body.querySelector(".page-seja-cliente")) return;

    const BE_A_CUSTOMER_STEP1 = document.querySelector(".page-seja-cliente__step-one");
    const BE_A_CUSTOMER_STEP2 = document.querySelector(".page-seja-cliente__step-two");
    const BE_A_CUSTOMER_MODAL = document.querySelector(".page-seja-cliente__step-modal");

    const BE_A_CUSTOMER_NEXT_BUTTON = BE_A_CUSTOMER_STEP1.querySelector(".button-next");
    const BE_A_CUSTOMER_FORM_BUTTON = BE_A_CUSTOMER_STEP2.querySelector(".button-form");

    const BE_A_CUSTOMER_MODAL_BUTTON_CLOSE = BE_A_CUSTOMER_MODAL.querySelector(".button-close-modal");

    BE_A_CUSTOMER_NEXT_BUTTON.addEventListener("click", (e) => {
        BE_A_CUSTOMER_STEP1.classList.add("js-is-hide");
        BE_A_CUSTOMER_STEP2.classList.add("js-is-show");
    });

    BE_A_CUSTOMER_FORM_BUTTON.addEventListener("click", (e) => {
        e.preventDefault();

        BE_A_CUSTOMER_MODAL.classList.add("js-is-open");
    });

    BE_A_CUSTOMER_MODAL_BUTTON_CLOSE.addEventListener("click", (e) => {
        BE_A_CUSTOMER_MODAL.classList.remove("js-is-open");
    });

    window.addEventListener("keydown", (e) => {
        if(e.key === "Escape") {
            BE_A_CUSTOMER_MODAL.classList.remove("js-is-open");
        } else {
            return;
        }
    });
};
