export default () => {
    const SWIPER_AVAILABLE_FORMATS = Array.from(document.querySelectorAll(".swiper-available-formats .swiper-slide"));

    if(SWIPER_AVAILABLE_FORMATS && window.innerWidth > 900) {
        SWIPER_AVAILABLE_FORMATS.map((item, index) => {
            let imgThisSwiperSlide = item.querySelector("img");
            item.removeAttribute("class");

            setTimeout(() => {
                item.style.width = `${imgThisSwiperSlide.width + 10}px`;
            }, 1000);
        });
    }
};
